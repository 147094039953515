body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.bg-checked {
  background-color: #b8e994;
}

.update-service { 
  font-weight: 500;
  font-size: 32px 
}

.update-title {
  font-weight: 500;
  font-size: 20px;
  color: #0078D4;
}

.update-images-container {
  background-color: #f2f2f2;
  border-radius: 16px;
  position: relative;
}

.update-banner-wrapper {
  position: absolute;
  top: 16px;
  right: 0;
  height: 80px;
  width: 200px;
  background-color: #243A5E;
  color: #fff;
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.update-banner-wrapper .update-banner-status {
  width: 10px;
  height: 100%;
}

.update-banner-wrapper .update-banner-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 16px;
}

.update-images-container .update-image {
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid black;
}

.update-images-container .update-images-list { 
  height: 100%; 
  overflow-y: scroll; 
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer;
}

.mark-update-checked-wrapper {
  
  border-radius: 50px;
  padding: 4px 16px;
  color: white;
  cursor: pointer;
  opacity: .7;
  margin-left: 4px;
}

.mark-update-checked-wrapper:hover {
  opacity: 1;
}

.action-wrapper {
  position: absolute;
  right: 8px;
  top: 0;
  display: flex;
  
}


